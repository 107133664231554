import React from 'react';
import dayjs from 'dayjs';
import lodash from 'lodash';
import PropTypes from 'prop-types';

import {Button, Grid, Typography, Box, FormControl, InputLabel, MenuItem} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import ReportingTable from './ReportingTable';
import PackageSelection from './PackageSelection';
import { readReportingData, combineAsyncJobs, sortTableByMonths } from '../../utils/apiCommonReportingMethods';
import { getGeneralAPIRequest} from '../../utils/apiCommonMethods';

// ----------------------------------------------------------------------

const today = dayjs();
const firstOfPreviousMonth = today.add(-1, 'month').startOf('month');

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const monthPicklistFormat = 'MMMM YYYY';

export async function getReportingData(axiosSession, site, month, selectedPackage){
  const paramsVar = new URLSearchParams();
  paramsVar.append("siteId", site.siteId);
  paramsVar.append("package", selectedPackage); 
  if (month !== null){
    const monthAsDate = dayjs(month, monthPicklistFormat);
    const monthPlusOne = monthAsDate.add(1, 'month');
    paramsVar.append("start",monthAsDate.format()); 
    paramsVar.append("end",monthPlusOne.format()); 
  }
  const apiRequest = {
    params: paramsVar
  };
  const apiResponse = await axiosSession.get('/reporting/summary', apiRequest);
  const cleanTableData = readReportingData(apiResponse.data);
  return cleanTableData;
}

export function getMonthPicklistOptions(startDate, endDate){
  const monthOptions = [];
  let loopDate = endDate;
  for (let i = 0; i < 12; i += 1) {
    if (loopDate >= startDate){
      monthOptions.push(loopDate.format(monthPicklistFormat));
    }
    loopDate = loopDate.add(-1, 'month');
  }
  return monthOptions;
}

export async function getMultipleMonthsDataAsync(axiosSession, activeSite, months, selectedPackages, existingTables){
  const asyncJobs = [];

  // One job for each package type and month
  for (let i = 0; i < months.length; i += 1) {
    for (let k = 0; k < selectedPackages.length; k += 1) {
      asyncJobs.push(
        getReportingData(axiosSession, activeSite, months[i], selectedPackages[k])
      );
    }
  }
  const allResults = await Promise.all(asyncJobs);
  const newCombinedData = combineAsyncJobTables(existingTables, allResults);
  return newCombinedData; 
}

export function combineAsyncJobTables(existingTable, asyncJobsResults){
  let combinedDataTables = combineAsyncJobs(existingTable, asyncJobsResults)
  combinedDataTables = sortTableByMonths(combinedDataTables, monthPicklistFormat);
  return combinedDataTables;
}

export function toggleReportTypes(selectedReportTypes, checkedValues){
  const newlySelectedReports = [];

  for (let i = 0; i < selectedReportTypes.length; i += 1) {
    const reportType = selectedReportTypes[i].tableApiName;
    let showReport = false;

    for (let j = 0; j < checkedValues.length; j += 1){
      const selectedPackage = checkedValues[j];
      if (reportType === selectedPackage){
        showReport = true;
      }
    }
    selectedReportTypes[i].showReport = showReport;
    newlySelectedReports.push(selectedReportTypes[i]);
  }
  return newlySelectedReports;
}

export function toggleSelectedMonths(monthsWithData, monthSelections, dataTable){
  const monthsToHide = lodash.difference(monthsWithData,monthSelections);
  const newTable = [];
  for (let j = 0; j < dataTable.length; j += 1){
    const reportTypeTableMonths = dataTable[j].data;
    for (let i = 0; i < reportTypeTableMonths.length; i += 1){
      const monthColumn = reportTypeTableMonths[i];
      const isInHideList = (lodash.indexOf(monthsToHide, monthColumn.month) !== -1);
      if (isInHideList){
        monthColumn.showColumn = false;
      } else {
        monthColumn.showColumn = true;
      } 
    }
    newTable.push(dataTable[j]);
  }
  return newTable;
}

ReportingSummaryComponent.propTypes = {
  msSessionInfo: PropTypes.object.isRequired,
  singleSite: PropTypes.object.isRequired,
};

export default function ReportingSummaryComponent({msSessionInfo, singleSite}) {
  // Month Options = all months available in picklist
  // Month Selections = all months the user has chosen
  // Months Without Data = new months that haven't been sent to API yet
  // Months With Data = months that we have pulled data from API already (cache)
  const [monthOptions, setMonthOptions] = React.useState([]);
  const [monthSelections, setMonthSelections] = React.useState([]);
  const [monthsWithoutData, setMonthsWithoutData] = React.useState([]);
  const [monthsWithData, setMonthsWithData] = React.useState([]);

  const [selectedReportTypes, setSelectedReportTypes] = React.useState([]);
  const [progress, setProgress] = React.useState();
  const [disableMonthSelection, setDisableMonthSelection] = React.useState(false);
  const [selectedPackages, setSelectedPackages] = React.useState([]);

  const allPackages = singleSite.availablePackages;
  const siteReportingStartDate = dayjs(singleSite.startDate);

  const handleDownloadButton = (event) => {
    // to do
    console.log(event);

  };

  const handleMonthChange = (event) => {
    const {target: { value }} = event;
    const newSelectedMonths = typeof value === 'string' ? value.split(',') : value;
    const newMonthsWithoutData = lodash.difference(newSelectedMonths,monthsWithData);
    setMonthSelections(newSelectedMonths);
    setMonthsWithoutData(newMonthsWithoutData);
  };

  const generateNewReport = async () => {
    const activeSite = singleSite;

    resetLoadingTimer();
    const interval = startLoadingTimer();
    let newCombinedData = selectedReportTypes;
    
    const axiosSession = getGeneralAPIRequest(msSessionInfo);

    // Only do callout if new months were added: 
    if (monthsWithoutData.length !== 0){
      if (monthsWithData.length === 0){
        newCombinedData = await getMultipleMonthsDataAsync(axiosSession, activeSite, monthsWithoutData, selectedPackages, []);
      } else {
        newCombinedData = await getMultipleMonthsDataAsync(axiosSession, activeSite, monthsWithoutData, selectedPackages, selectedReportTypes);
      }
      monthsWithData.push(...monthsWithoutData);
      setMonthsWithData(monthsWithData);
    }

    newCombinedData = toggleSelectedMonths(monthsWithData,monthSelections, newCombinedData);
    newCombinedData = toggleReportTypes(newCombinedData, selectedPackages);

    setSelectedReportTypes(newCombinedData);
    endLoadingTimer(interval);
  }

  React.useEffect(() => {
    let calledOnceOnPageLoad = false;
    if (!calledOnceOnPageLoad && singleSite) {
      calledOnceOnPageLoad = true;
      const monthVarOptions = getMonthPicklistOptions(siteReportingStartDate, firstOfPreviousMonth);
      setMonthOptions(monthVarOptions);
    }
  }, [msSessionInfo, singleSite]);

  const handleReportingPackageChange = (checkedValues) => {
    setSelectedPackages(checkedValues);

    // If selected packages are changed, we want to refresh all 
    // data from the API next time "Generate Reports" button is pushed
    setMonthsWithoutData(monthSelections);  
    setMonthsWithData([]);

    const newlySelectedReports = toggleReportTypes(selectedReportTypes, checkedValues);
    setSelectedReportTypes(newlySelectedReports);
  }

  const resetLoadingTimer = () => {
    setDisableMonthSelection(true);
    setProgress(0);
  }

  const startLoadingTimer = () => {
    setProgress(20);
    let counter = 20;
    return setInterval(() => {
      counter += 5;
      if (counter < 90){
        setProgress(counter);
      }
    }, 1000);
  }

  const endLoadingTimer = (interval) => {
    setDisableMonthSelection(false);
    clearInterval(interval);
    setProgress(100);
  }

  return (
    <>
      <Grid container spacing={3} direction="row" alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={8} lg={8}>
          <Grid container spacing={3} direction="row" alignItems="stretch" justifyContent="center">
            {monthOptions.length === 0 ?
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant="body2" sx={{ p: 0.5, pb: 1, textAlign: 'center' }}>
                  This site has not been operational for long enough to generate a report. 
                </Typography>
                <Typography variant="body2" sx={{ p: 0.5, pb: 1, textAlign: 'center' }}>
                  Please check back later.
                </Typography>
              </Grid>
             : (             
              <Grid item xs={12} md={12} lg={12}>
                <FormControl disabled={disableMonthSelection} size="small" sx={{ bgcolor: 'background.paper', width: 1 }}>
                  <InputLabel id="multi-select-label">Select Time Period</InputLabel>
                  <Select
                    labelId="multi-select-label"
                    id="month-select-multiple-checkbox"
                    multiple
                    value={monthSelections}
                    onChange={handleMonthChange}
                    input={<OutlinedInput label="Select Time Period" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {monthOptions.map((month) => (
                      <MenuItem key={month} value={month}>
                        <Checkbox checked={monthSelections.indexOf(month) > -1} />
                        <ListItemText primary={month} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {progress < 100 ?
              <Box sx={{ width: '100%', p: 10 }}>
                <Typography variant="body2" sx={{ p: 0.5, pb: 5, textAlign: 'center' }}>
                  Generating Report...
                </Typography>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
              : (null)}
            {selectedReportTypes.map((reportTable) => (
              <Grid key={reportTable.tableKey} item xs={12} md={12} lg={12}>
                {(reportTable.showReport && progress === 100) ? (
                  <ReportingTable tableKey={reportTable.tableKey} title={reportTable.tableName} labels={reportTable.measureLabels} rows={reportTable.data} />
                ) : (null)}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Grid container spacing={3} direction="row" alignItems="stretch" justifyContent="center">
            <Grid item xs={12} md={12} lg={12}>
              <Button sx={{ width: 1 }} variant="contained" onClick={generateNewReport}>
                <Typography variant="body2" sx={{ p: 0.5 }}>
                  Generate Report
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <PackageSelection actionToTakeOnChange={handleReportingPackageChange} allPackages={allPackages} />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {/* <Button sx={{ bgcolor: 'background.paper', width: 1 }} variant="outlined" onClick={handleDownloadButton}>
                <Typography variant="body2" sx={{ p: 0.5 }}>
                  Download Data for Site
                </Typography>
              </Button> */}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {/* <Button sx={{ bgcolor: 'background.paper', width: 1 }} variant="outlined" onClick={handleDownloadButton}>
                <Typography variant="body2" sx={{ p: 0.5 }}>
                  Download Remittance Statement
                </Typography>
              </Button> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
