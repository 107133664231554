import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ReportingTableColumn from './ReportingTableColumn';

export default function ReportingTable ({title, tableKey, labels, rows}) {
  const [columnCount, setColumnCount] = useState(1);

  useEffect(() => {
    let visibleColumnsCount = 1;
    for (let i = 0; i < rows.length; i += 1) {
      if (rows[i].showColumn){
        visibleColumnsCount += 1;
      }
    }
    setColumnCount(visibleColumnsCount);
  });

  return (
    <>       
      <TableContainer key={tableKey} component={Paper} sx={{border : 1, borderColor:'primary.main'}}>
        <Table sx={{overflowX: 'scroll'}} stickyHeader padding="none" size="small" aria-label="report table">
          <TableHead >
            <TableRow>
              <TableCell colSpan={columnCount} align="center" sx={{bgcolor: (theme) => theme.palette.primary.main}}>
                <Typography variant="body2" sx={{color: (theme) => theme.palette.grey[0], pt:0.5, pb:0.5, textAlign: 'center'}}>
                  {title}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" sx={{pl:1}}>&nbsp;</TableCell>
              {rows?.map((row, index) => (
                row.showColumn ? 
                <TableCell key={index} align="right" sx={{pr:1}}>{row.month}</TableCell>
                : (null)
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          {labels?.map((measureLabel, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell sx={{pl:1, minWidth: 200}} align="left">{measureLabel}</TableCell>
              {rows?.map((row, rowIndex) => (
                row.showColumn ? 
                  <ReportingTableColumn key={rowIndex} labelIndex={index} valueSet={row.values}/>
                : (null)
              ))}
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer> 
    </>
  );
}