import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#06284B',
  400: '#06284B',
  500: '#06284B',
  600: '#06284B',
  700: '#06284B',
  800: '#06284B',
  900: '#06284B',
};

const PRIMARY = {
  lighter: '#06284B',
  light: '#06284B',
  main: '#06284B',
  dark: '#06284B',
  darker: '#06284B',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '#b1e9ff',
  light: '#b1e9ff',
  main: '#00B6FF',
  dark: '#005476',
  darker: '#005476',
  contrastText: '#fff',
};

const TERTIARY = {
  yellow: '#FAED26',
  green: '#AFD275'
};

const INFO = {
  lighter: '#05AFEC',
  light: '#05AFEC',
  main: '#05AFEC',
  dark: '#05AFEC',
  darker: '#05AFEC',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const DISABLE = {
  main: '#C4CDD5'
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  tertiary: TERTIARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  disable: DISABLE,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff', // nav bar, login page
    default: GREY[0], // default for pages, 404
    neutral: GREY[200], // app pages
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
