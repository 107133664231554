const prod = {
    baseSYSOAPIUrl : "https://portal.api.syso.cloud/v1",
    msClientId : "c19fda2d-8606-4b0c-b285-fa28ff2f3a99",
    msScopeId : "41d86d2f-5ec2-424e-a154-68ff9f324883" 
};

const dev = {
    baseSYSOAPIUrl : "https://portal.api.dev-syso.cloud/v1",
    msClientId : "b76de91e-9daf-4176-816c-815bd631c8df",
    msScopeId : "cc055bc3-d379-4db8-b7f3-2b11e317c4d9"
};

let currentEnv;
try {
    currentEnv = window.location.hostname === 'app.syso.cloud' ? 'prod' : 'dev';
} catch (excep){
    currentEnv = 'dev';
}

export const env = currentEnv === 'dev' ? dev : prod;