import * as React from 'react';
import { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function PackageSelection({actionToTakeOnChange, allPackages}) {
  const [checked, setChecked] = React.useState([]);

  useEffect(() => {
    // default = all available packages selected
    const defaultSelections = []
    if (allPackages){
      for (let i = 0; i < allPackages.length; i += 1){
        defaultSelections.push(allPackages[i].apiName);
      }
      setChecked(defaultSelections);
      actionToTakeOnChange(defaultSelections);
    } else {
      allPackages = [];
    }
  }, [allPackages]);  

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    actionToTakeOnChange(newChecked);
  };

  return (    
    <>       
      <List sx={{ width: '100%', border: 1, borderColor: (theme) => theme.palette.primary.main, borderRadius: 2 }} >
        <Box >
          <Typography variant="body2" sx={{ color: (theme) => theme.palette.primary.main, pb: 0.5, textAlign: 'center' }}>
            Selected Packages
          </Typography>
        </Box>
        {allPackages?.map((pckg) => (
          <ListItem key={pckg.apiName} sx={{ bgcolor: 'background.paper' }}>
            <ListItemText disableTypography primary={<Typography variant="body2">{pckg.label}</Typography>} />
            <Switch
              edge="end"
              onChange={handleToggle(pckg.apiName)}
              checked={checked.indexOf(pckg.apiName) !== -1}
              inputProps={{
                'aria-labelledby': 'switch-list-label-one',
              }}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
}