import lodash from 'lodash';
import dayjs from 'dayjs';
import {tableApiNameToLabel, measureApiNameToConfigsMap, reportingPackagesEnabledForDetail, demoSiteNames, useDemoSiteNames} from '../apiConfig';

const createTable = (tableName, tableApiName, tableKey, month, reportData) => {
  const table = { tableName, tableApiName, tableKey, month, reportData};
  return table;
};

export const getUniqueSites = (allSTSes) => {
  const siteIdToName = {}
  const allSites = []

  for (let i = 0; i < allSTSes.length; i += 1) {
    if (allSTSes[i].siteId && allSTSes[i].displayName){
      let siteName = allSTSes[i].displayName.replace(/[0-9]/g, '');
      siteName = siteName.replace(/\s/g, '');
      siteIdToName[allSTSes[i].siteId] = siteName;
    }
  }
  const uniqueSiteIds = Object.keys(siteIdToName);
  for (let j = 0; j < uniqueSiteIds.length; j += 1) {
    const newSite = {};
    newSite.siteId = uniqueSiteIds[j];
    newSite.displayName = siteIdToName[uniqueSiteIds[j]];
    if (useDemoSiteNames){
      newSite.displayName = demoSiteNames[j]; 
    }
    allSites.push(newSite);
  }
  return allSites;
}

export const readPackageData = (uniqueSites, rawPackageResp) => {
  for (let j = 0; j < rawPackageResp.length; j += 1) {
    const siteResp = rawPackageResp[j];
    siteResp.availablePackagesWithLabel = [];
    for (let k = 0; k < siteResp.availablePackages.length; k += 1) {
      const singlePack = siteResp.availablePackages[k];
      const newRow = {}
      newRow.label = tableApiNameToLabel.get(singlePack);
      newRow.apiName = singlePack;
      newRow.detailReportingEnabled = reportingPackagesEnabledForDetail.get(singlePack);
      siteResp.availablePackagesWithLabel.push(newRow);
    }
  }

  for (let i = 0; i < uniqueSites.length; i += 1) {
    const siteRow = uniqueSites[i];
    for (let m = 0; m < rawPackageResp.length; m += 1) {
      const packageRow = rawPackageResp[m];
      if (siteRow.siteId === packageRow.siteId){
        siteRow.availablePackages = packageRow.availablePackagesWithLabel;
        siteRow.startDate = packageRow.startDate;
      }
    }
  }

  for (let k = 0; k < uniqueSites.length; k += 1) {
    const siteRow = uniqueSites[k];
    if (siteRow.availablePackages){
      const sitePackages = siteRow.availablePackages;
      for (let m = 0; m < sitePackages.length; m += 1) {
        // IF any of the packages have detail reporting, show it
        if (sitePackages[m].detailReportingEnabled){
          siteRow.detailReportingEnabled = true;
        }
      }
    }
  }
  return uniqueSites;
}

export const readReportingData = (rawAPIOutput) => {
  const reportingPackages = []
  const reportKeys = Object.keys(rawAPIOutput.reports); 
  
  const startDate = dayjs(rawAPIOutput.start);
  const startMonth = startDate.format('MMMM YYYY');

  for (let i = 0; i < reportKeys.length; i += 1) {
    const reportKey = reportKeys[i];
    const keyParts = reportKey.split(":");
    const packageName = keyParts[0];
    const tableLabel = tableApiNameToLabel.get(packageName);
    if (tableLabel !== undefined){
      if (tableLabel === 'Battery Health'){
        // Unlike other packages, Battery Health should create table for each STS
        const systemList = rawAPIOutput.reports[reportKey].systems;
        for (let j = 0; j < systemList.length; j += 1) {
          const cleanSubTableData = readReportingTable(systemList[j]);
          let subTableLabel = tableLabel;
          if (systemList.length > 1){
            subTableLabel = `${tableLabel} - ${systemList[j].name}`;
          } 
          const key = `batt${j.toString()}`;
          const newCleanSubTable = createTable(subTableLabel, packageName, key, startMonth, cleanSubTableData);
          reportingPackages.push(groupDataByMonth(newCleanSubTable));
        }
      } else {
        const tableData = rawAPIOutput.reports[reportKey];
        const cleanTableData = readReportingTable(tableData);
        const tableKey = packageName + i;
        const newCleanTable = createTable(tableLabel, packageName, tableKey, startMonth, cleanTableData);
        reportingPackages.push(groupDataByMonth(newCleanTable));
      }
    }
  }
  return reportingPackages;
}

const readReportingTable = (apiOutputSingleTable) => {
  const cleanReportData = {}
  const measureKeys = Object.keys(apiOutputSingleTable);

  for (let i = 0; i < measureKeys.length; i += 1) {
    const measureKey = measureKeys[i];
    const measureConfigs = measureApiNameToConfigsMap.get(measureKey);
    let measureValue = apiOutputSingleTable[measureKey];
    if (measureConfigs !== undefined){
      if (measureConfigs.type === 'decimal' || measureConfigs.type === 'currency'){
        const decimalCount = measureConfigs.decimalPlaces;
        // To Locale String adds thousand commas to numbers
        measureValue = measureValue.toLocaleString('en-US', {maximumFractionDigits: decimalCount, minimumFractionDigits: decimalCount});
        if (measureConfigs.type === 'currency'){
          cleanReportData[measureConfigs.label] = `$${measureValue}`;
        } else {
          cleanReportData[measureConfigs.label] = measureValue;
        }
      } else if (measureConfigs.type === 'datetime'){
        const dateAsDate = dayjs(measureValue);
        const dateStr = dateAsDate.format('YYYY-MM-DD HH:mm');
        cleanReportData[measureConfigs.label] = dateStr;
      } else {
        cleanReportData[measureConfigs.label] = measureValue;
      }
    }
  }
  return cleanReportData;
}

const groupDataByMonth = (newTable) => {
  const tableByMonth = instantiateNewCombinedTable(null, newTable);
  const allMeasureNames = Object.keys(newTable.reportData);

  tableByMonth.measureLabels = allMeasureNames;
  const newDataColumn = {'month' : newTable.month, 'values' : [], 'showColumn' : true};
  for (let k = 0; k < allMeasureNames.length; k += 1) {
    const newCellToAdd = newTable.reportData[allMeasureNames[k]];
    newDataColumn.values.push(newCellToAdd);
  }
  tableByMonth.data.push(newDataColumn);
  return tableByMonth;
}

export const combineAsyncJobs = (existingTable, jobs) => {
  let tableKeys = []
  let leftOverList = []
  let newTables = []
  // If table doesn't exist already, instantiate it with 
  // the first item of each package type
  if (existingTable.length === 0){
    for (let j = 0; j < jobs.length; j += 1){
      for (let k = 0; k < jobs[j].length; k += 1){
        const jobResult = jobs[j][k];
        const tableKeyVar = jobResult.tableKey;
        if (lodash.includes(tableKeys, tableKeyVar)){
          leftOverList.push([jobs[j][k]]);
        } else {
          existingTable.push(jobResult);
          tableKeys.push(tableKeyVar)
        }
      }
    }
    tableKeys = [];
    jobs = leftOverList;
    leftOverList = [];
    newTables = [];
  }
  if (jobs.length > 0){
    for (let j = 0; j < jobs.length; j += 1){
      for (let k = 0; k < jobs[j].length; k += 1){
        const jobResult = jobs[j][k];
        const tableKeyVar = jobResult.tableKey;
        if (lodash.includes(tableKeys, tableKeyVar)){
          leftOverList.push(jobs[j][k]);
        } else {
          newTables.push(jobResult);
          tableKeys.push(tableKeyVar)
        }
      }
    }
    // Merge one month of data at a time: 
    let newCombinedData = combineMonthsOfSameReportType(existingTable, newTables);
    // Requeue with additional months: 
    if (leftOverList.length > 0){
      newCombinedData = combineAsyncJobs(newCombinedData, leftOverList);
    }
    return newCombinedData;
  } 
  return existingTable;
}

export const combineMonthsOfSameReportType = (oldTables, newTables) => {
  // Matches row in newTable to value in oldTable and merges
  // Expects 1:1 on package type
  const newCombinedTables = [];
  for (let k = 0; k < oldTables.length; k += 1) {
    const oldTable = oldTables[k];
    for (let j = 0; j < newTables.length; j += 1) {
      const newTable = newTables[j];
      if (oldTable.tableKey === newTable.tableKey){
        newCombinedTables.push(combineTables(oldTable, newTable));
      }
    }
  }
  return newCombinedTables;
}

export const combineTables = (oldTable, newTable) => {
  let newCombinedTable = instantiateNewCombinedTable(oldTable, newTable);
  const allMeasureNames = getSupersetOfMeasures(oldTable, newTable);
  newCombinedTable.measureLabels = allMeasureNames;

  if (newTable !== null){
    newCombinedTable = addTableToList(newTable, allMeasureNames, newCombinedTable);
  }
  if (oldTable !== null){
    newCombinedTable = addTableToList(oldTable, allMeasureNames, newCombinedTable);
  }
  return newCombinedTable;
}

const addTableToList = (tableVar, allMeasureNames, newCombinedTable) => {
  const existingMeasureNames = tableVar.measureLabels;

  for (let j = 0; j < tableVar.data.length; j += 1) {
    const oneMonth = tableVar.data[j];
    const newColumn = { 'month': oneMonth.month, 'values': [] , 'showColumn' : oneMonth.showColumn};
    for (let i = 0; i < allMeasureNames.length; i += 1) {
      const oldIndex = existingMeasureNames.indexOf(allMeasureNames[i]);
      newColumn.values.push(oneMonth.values[oldIndex]);
    }
    newCombinedTable.data.push(newColumn);
  }
  return newCombinedTable;
}

const getSupersetOfMeasures = (oldTable, newTable) => {
  // GET SUPERSET OF KEYS
  const supersetOfMeasures = [];
  supersetOfMeasures.push(...oldTable.measureLabels);
  supersetOfMeasures.push(...newTable.measureLabels);
  const uniqueSet = lodash.uniq(supersetOfMeasures);
  return uniqueSet;
}

const instantiateNewCombinedTable = (oldTable, newTable) => {
  const newCombinedTable = {
    'measureLabels' : [],
    'data' : []
  };
  if (oldTable !== null){
    newCombinedTable.tableName = oldTable.tableName;
    newCombinedTable.tableApiName = oldTable.tableApiName;
    newCombinedTable.tableKey = oldTable.tableKey;
    newCombinedTable.showReport = newTable.showReport;
  } else {
    newCombinedTable.tableName = newTable.tableName;
    newCombinedTable.tableApiName = newTable.tableApiName;
    newCombinedTable.tableKey = newTable.tableKey;
    newCombinedTable.showReport = true;
  }
  return newCombinedTable;
}

export const sortTableByMonths = (table, monthFormat) => {
  for (let i = 0; i < table.length; i += 1) {
    const reportTableColumns = table[i].data;
    for (let j = 0; j < reportTableColumns.length; j += 1){
      reportTableColumns[j].monthAsDate = dayjs(reportTableColumns[j].month, monthFormat);
    }
    const newList = lodash.sortBy(reportTableColumns, 'monthAsDate');
    table[i].data = newList;
  }
  return table;
}