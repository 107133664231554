
import PropTypes from 'prop-types';
import { Card, Typography } from '@mui/material';

AppSiteStatusCard.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subsubtitle: PropTypes.string,
  sx: PropTypes.object,
};

export default function AppSiteStatusCard({ title, subtitle, subsubtitle, color = 'primary', sx, ...other }) {
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        borderRadius: 2,
        border : 2,
        color: (theme) => theme.palette[color].main,
        bgcolor: (theme) => theme.palette.grey[0],
        ...sx,
      }}
      {...other}
    >

      <Typography sx={{color: (theme) => theme.palette.grey[800]}} variant="h4">
        {title}
      </Typography>
      <Typography sx={{color: (theme) => theme.palette.grey[800], opacity: 0.72}}>
        {subtitle}
      </Typography>
      <Typography sx={{color: (theme) => theme.palette.grey[800], opacity: 0.72}}>
        {subsubtitle}
      </Typography>
    </Card>
  );
}
