import { Box, Container, Card, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableRow, IconButton} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { getGeneralAPIRequest, transformBessStatusData } from '../../utils/apiCommonMethods';
import SiteStatusCharts from './SiteStatusCharts';


const todayMidnight = dayjs().startOf('day');
const tomorrowMidnight = todayMidnight.add(1, 'day');
const thirtydaysago = todayMidnight.subtract(30, 'day');

SiteStatus.propTypes = {
  msSessionInfo: PropTypes.object,
  site: PropTypes.object
};

export async function getBessStatus(axiosSession, site) {
  const bessStatusrequest = {
    params: { "stsId": site.stsId }
  };
  const statusResponse = await axiosSession.get('/bess/status', bessStatusrequest);
  const transformedData = transformBessStatusData(statusResponse.data, [site]);
  const batteryPowerText = `${transformedData[0].chargingStatus} (${transformedData[0].currentPowerText})`;
  const siteTableData = [
    { "label": "Current Mode", "value": transformedData[0].currentModeText },
    { "label": "Current BESS Power", "value": batteryPowerText },
    { "label": "Current SOC", "value": transformedData[0].currentSOCText }
  ];
  return siteTableData;
}

export async function getBessTimeseries(axiosSession, site, startDate, endDate){
  const paramsVar = new URLSearchParams();
  paramsVar.append("stsId", site.stsId);
  paramsVar.append("bin", "PT15M");
  paramsVar.append("points", "soc");
  paramsVar.append("points", "power");
  if (site.iso !== 'nyiso') {
    paramsVar.append("points", "poi_output");
  }
  if (startDate !== null && endDate !== null){
    paramsVar.append("start", startDate.format());
    paramsVar.append("end", endDate.format());
  }
  const tsRequest = {
    params: paramsVar
  };
  const tsResponse = await axiosSession.get('/bess/timeseries', tsRequest);
  return tsResponse.data;
}

export async function getPricingData(axiosSession, site, startDate, endDate){
  const pricingRequest = {
    params: { "start": startDate.format(), "end": endDate.format()}
  };
  const endpoint = `/prices/${site.iso}/zones/${site.isoZone}/timeseries`;
  const pricingResponse = await axiosSession.get(endpoint, pricingRequest);
  return pricingResponse.data;
}

export async function getOptimizationData(axiosSession, site, startDate, endDate, horizon, optimType){
  let optimResponse;  
  
  const optimizationRequest = {
      params: {
        "stsId":site.stsId,
        "points":["power","soc"],
        "bin":"PT1H",
        "start": startDate.format(),
        "end": endDate.format(),
      }
  };
  
  if (horizon){
    optimizationRequest.params.horizon = horizon;
  }

  try {
    optimResponse =  await axiosSession.get(`/optimization/${optimType}/timeseries`, optimizationRequest);
  } catch (error){
    console.log(error);
    return null;
  }
  
  return optimResponse.data;
}

export function sumOptimSoc(optimSocs){

  // Remove any undefined values
  optimSocs = _.compact(optimSocs)

  const totalSoc = _(_.concat(...optimSocs))
  .groupBy('time')
  .map((objs, key) => ({'time': key, 'value': _.sumBy(objs, 'value') }))
  .orderBy(['time'], ['asc'])
  .value();

  return totalSoc;
}

export function queueDataJobs(axiosSession, site, startDate, endDate){
  const asyncJobs = [];
  asyncJobs.push(getBessStatus(axiosSession, site));
  asyncJobs.push(getBessTimeseries(axiosSession, site, startDate, endDate));
  if (site.iso === 'nyiso') {
    let horizonVar = null;
    if (endDate < todayMidnight){
      horizonVar = "PT1H";
    }
    asyncJobs.push(getPricingData(axiosSession, site, startDate, endDate));
    asyncJobs.push(getOptimizationData(axiosSession, site, startDate, endDate, horizonVar, 'vder'));
    asyncJobs.push(getOptimizationData(axiosSession, site, startDate, endDate, horizonVar, 'dlm'));
  }
  return asyncJobs;
}

export function parseResponseDataJobs(bessStatus, tsStatus, pricingData, optimDataVder, optimDataDlm){
  const allChartData = {}
  allChartData.bessStatus = bessStatus
  allChartData.tsStatus = tsStatus
  if (pricingData) {
    allChartData.pricing = pricingData
  }
  if (optimDataVder && optimDataDlm) {
    allChartData.optimization = {};
    allChartData.optimization.vder = optimDataVder
    allChartData.optimization.dlm = optimDataDlm
    allChartData.optimization.soc = sumOptimSoc([allChartData.optimization.vder.soc, allChartData.optimization.dlm.soc]);
  }
  return allChartData;
}

export default function SiteStatus({ msSessionInfo, site }) {
  const [showStatusTable, setShowStatusTable] = useState(true);
  const [selectedDate, setDate] = useState(todayMidnight);
  const [siteTableData, setSiteTableData] = useState();
  const [allChartData, setAllChartData] = useState();
  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState();
  const [loading, setLoading] = useState();

  const getLastUpdateTimeString = () => `Last Updated ${dayjs().format('hh:mm:ss')}`;

  const handleRefresh = async () => {
    const newStartDate = selectedDate;
    const newEndDate = newStartDate.add(1, 'day').subtract(1, 'minute'); 
    setLastUpdatedTimestamp(getLastUpdateTimeString);
    fetchAllData(msSessionInfo, site, newStartDate, newEndDate);
  };

  const handleDateChange = async (newValue) => {
    const newStartDate = newValue;
    const newEndDate = newStartDate.add(1, 'day').subtract(1, 'minute'); 
    setDate(newStartDate);
    setLastUpdatedTimestamp(getLastUpdateTimeString);
    fetchAllData(msSessionInfo, site, newStartDate, newEndDate);
  };

  const fetchAllData = async (msSessionInfo, site, startDate, endDate) => {
    setLoading(true);
    const axiosSession = getGeneralAPIRequest(msSessionInfo);
    const asyncJobs = queueDataJobs(axiosSession, site, startDate, endDate)
    Promise.all(asyncJobs)
      .then(([bessStatus, tsStatus, pricingData, optimDataVder, optimDataDlm]) => {
        const allChartData = parseResponseDataJobs(bessStatus, tsStatus, pricingData, optimDataVder, optimDataDlm);
        if (startDate.isAfter(todayMidnight, 'day')){
          // Date is in future, clear Power and SOC charts but not Optim:
          allChartData.tsStatus = {"power" : [], "soc" : []};
        } 
        setSiteTableData(allChartData.bessStatus)
        setAllChartData(allChartData)
      }).catch(err => console.log(err))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (site != null) {
      setLastUpdatedTimestamp(getLastUpdateTimeString);
      fetchAllData(msSessionInfo, site, todayMidnight, tomorrowMidnight);
    }
  }, [msSessionInfo, site]);

  return (
    <>
      <Container maxWidth="xl">
        {loading ?
          <Box sx={{
            width: '100%',
            height: '100vh', // Full viewport height
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Horizontally center
            p: 10,
          }}>
            <CircularProgress/>
          </Box>
        : (
        <Grid container spacing={3} direction="row" alignItems="stretch" justifyContent="center">
          <Grid item xs={12} md={6} lg={6} order={{ xs: 2, sm: 2, md:1, lg:1}}>
            {showStatusTable ? (
              <Card>
                <TableContainer>
                  <Table size="small" aria-label="a dense table">
                    <TableBody>
                      {siteTableData?.map((row) => (
                        <TableRow
                          key={row.label}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {row.label}
                          </TableCell>
                          <TableCell align="right">{row.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6} lg={6} order={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
            <IconButton sx={{ pb: 2, float: 'right' }} size="small" aria-label="expand" onClick={() => handleRefresh()}>
              <RefreshIcon />
            </IconButton>
            <Typography sx={{ pt: 1, float: 'right' }} variant="caption">{lastUpdatedTimestamp}</Typography>
            <DatePicker
              label="Date"
              sx={{ bgcolor: 'background.paper', width: 1 }}
              minDate={thirtydaysago}
              maxDate={tomorrowMidnight}
              value={selectedDate}
              onChange={handleDateChange}
              views={['day']}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} order={{ xs: 3, sm: 3, md:3, lg:3}}>
            { /* Charts : */}
            <SiteStatusCharts chartData={allChartData} />
          </Grid>
        </Grid>
        )}
      </Container>
    </>
  );
}
