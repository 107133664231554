import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {Box, Button, Grid, Typography} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import PackageSelection from './PackageSelection';
import { getGeneralAPIRequest, fileDownload} from '../../utils/apiCommonMethods';

// ----------------------------------------------------------------------

ReportingDetailComponent.propTypes = {
  msSessionInfo: PropTypes.object.isRequired,
  singleSite: PropTypes.object.isRequired,
};

const topOfCurrentHour = dayjs().startOf('hour');
const defaultStartDate = topOfCurrentHour.subtract(7, 'day');

export async function getFileDownload(axiosSession, site, startDate, endDate, packages){
  const paramsVar = new URLSearchParams();
  paramsVar.append("siteId", site.siteId);
  for (let i = 0; i < packages.length; i += 1) {
    paramsVar.append("packages",packages[i].apiName); 
  }
  paramsVar.append("start",startDate.format()); 
  paramsVar.append("end",endDate.format()); 
  paramsVar.append("format","csv"); 
  const apiRequest = {
    params: paramsVar,
    responseType : 'blob'
  };
  const apiResponse = await axiosSession.get('/reporting/detail', apiRequest);
  return apiResponse.data;
}

export function filterPackages(allPackages){
  const detailPackages = [];
  for (let i = 0; i < allPackages.length; i += 1) {
    if (allPackages[i].detailReportingEnabled){
      detailPackages.push(allPackages[i]);
    }    
  }
  return detailPackages;
}

export function validateDates(selectedStartDate, selectedEndDate, siteReportingStartDate){
  if (selectedStartDate === null || selectedEndDate == null){
    return 'Start Date and End Date Must be Populated';
  } 
  if (selectedStartDate < siteReportingStartDate){
    return `Start Date must be after ${siteReportingStartDate.format('MM/DD/YYYY')}`;
  } 
  if (selectedEndDate > topOfCurrentHour){
    return `End Date must in the past`;
  } 
  if (selectedEndDate < selectedStartDate){
    return `End Date must occur after Start Date`;
  } 
  if (selectedStartDate.minute() !== 0 || selectedEndDate.minute() !== 0){
    return `Dates must end at the top of an hour`;
  }
  return null;
}

export default function ReportingDetailComponent({ msSessionInfo, singleSite }) {
  const [selectedStartDate, setStartDate] = React.useState(defaultStartDate);
  const [selectedEndDate, setEndDate] = React.useState(topOfCurrentHour);
  const [detailPackages, setPackages] = React.useState([]);
  const [progress, setProgress] = React.useState(100);
  const [errorMessage, setErrorMessage] = React.useState();

  const siteReportingStartDate = dayjs(singleSite.startDate);

  const handlePackageChange = (checkedValues) => {
    console.log('in package change');
  }

  const downloadData = async () => {
    const errorMessage = validateDates(selectedStartDate, selectedEndDate, siteReportingStartDate);
    console.log(errorMessage);
    setErrorMessage(errorMessage);
    if (errorMessage === null){
      const axiosSession = getGeneralAPIRequest(msSessionInfo);
      const interval = startLoadingTimer();
      const fileData = await getFileDownload(axiosSession, singleSite, selectedStartDate, selectedEndDate, detailPackages);
      const currentTime = dayjs().format();
      const fileName = `hourly_reporting_${currentTime}.csv`;
      fileDownload(fileName, fileData);
      endLoadingTimer(interval);
    } 
  }

  React.useEffect(() => {
    let calledOnceOnPageLoad = false;
    if (!calledOnceOnPageLoad && singleSite) {
      calledOnceOnPageLoad = true;
      const detailPackages = filterPackages(singleSite.availablePackages);
      setPackages(detailPackages);
    }
  }, [singleSite]);

  const startLoadingTimer = () => {
    setProgress(20);
    let counter = 20;
    return setInterval(() => {
      counter += 5;
      if (counter < 90){
        setProgress(counter);
      }
    }, 1000);
  }

  const endLoadingTimer = (interval) => {
    clearInterval(interval);
    setProgress(100);
  }

  return (
    <>
      <Grid container spacing={3} direction="row" alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={8} lg={8}>
          {errorMessage ?
            <Typography variant="body2" sx={{ p: 0.5, pb: 5, textAlign: 'center'}}>
              {errorMessage}
            </Typography>
          : (null)}
          <Grid container spacing={3} direction="row" alignItems="stretch" justifyContent="center">
            <Grid item xs={12} md={12} lg={12}>     
              {progress < 100 ?
                <Box sx={{ width: '100%', p: 10 }}>
                  <Typography variant="body2" sx={{ p: 0.5, pb: 5, textAlign: 'center' }}>
                    Downloading Report...
                  </Typography>
                  <LinearProgress variant="determinate" value={progress} />
                </Box>
              : (null)} 
              {progress === 100 ?        
                <DateTimePicker
                  label="Start Date"
                  sx={{ bgcolor: 'background.paper', width: 1 }}
                  minDate={siteReportingStartDate}
                  maxDate={topOfCurrentHour}
                  value={selectedStartDate}
                  onChange={setStartDate}
                  views={['year', 'month', 'day', 'hours']}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }} 
                /> 
              : (null)}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {progress === 100 ?        
                <DateTimePicker
                  label="End Date"
                  sx={{ bgcolor: 'background.paper', width: 1 }}
                  minDate={siteReportingStartDate}
                  maxDate={topOfCurrentHour}
                  value={selectedEndDate}
                  onChange={setEndDate}
                  views={['year', 'month', 'day', 'hours']}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              : (null)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Grid container spacing={3} direction="row" alignItems="stretch" justifyContent="center">
            <Grid item xs={12} md={12} lg={12}>
              <Button sx={{ width: 1 }} variant="contained" onClick={downloadData}>
                <Typography variant="body2" sx={{ p: 0.5 }}>
                  Download Data
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <PackageSelection actionToTakeOnChange={handlePackageChange} allPackages={detailPackages} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
