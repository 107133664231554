import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, Box, IconButton } from '@mui/material';
import ExpandContract from '@mui/icons-material/OpenInFull';
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

AppChartCard.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  doubleYAxis : PropTypes.bool,
  xaxisLabel : PropTypes.string
};

export default function AppChartCard({ title, subheader, chartLabels, chartData, expandContractChart, doubleYAxis, xAxisLabel, ...other }) {
  const yaxisvariant = doubleYAxis ? [{}, {show: false}, {opposite: true}] : [{}];
  const xaxislabel = xAxisLabel;
  const chartOptions = useChart({
    chart: { stacked: true },
    plotOptions: { bar: { columnWidth: '90%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { 
      type: 'datetime',  
      labels:{datetimeUTC: false},
      title: {
        text: xaxislabel,
        offsetY: 3,
        style: {
          color: undefined,
          fontSize: '12px',
          fontWeight: 100,
          cssClass: 'apexcharts-xaxis-title',
        },
      },
    },
    yaxis: yaxisvariant,
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        format: 'HH:mm'
      },
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y}`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} action={
        <IconButton size="small" aria-label="expand" onClick={() => expandContractChart()}>
          <ExpandContract/>
        </IconButton>
      }/>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={250} />
      </Box>
    </Card>
  );
}
