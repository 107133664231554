import { Navigate, useRoutes } from 'react-router-dom';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';
import axios from "axios";
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import SiteStatusPage from './pages/SiteStatusPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ReportingPage from './pages/ReportingPage';
import DashboardAppPage from './pages/DashboardAppPage';
import { tokenRequest } from './authConfig';
import { sysoApiConfig } from './apiConfig';
import { sortSites } from './utils/apiCommonMethods';

// ----------------------------------------------------------------------

const UnauthenticatedRouter = () => {
  const unauthenticatedRoutes = useRoutes([
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'redirect',
      element: <Navigate to="/"/>,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
  return unauthenticatedRoutes;
}

const AuthenticatedRouter = ({msSessionInfo, allSites}) => {
  const authenticatedRoutes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout/>,
      children: [
        { element: <Navigate to="/dashboard/home" />, index: true },
        { path: 'home', element: <DashboardAppPage msSessionInfo={msSessionInfo} allSites={allSites}/> },
        { path: 'sitestatus', element: <SiteStatusPage msSessionInfo={msSessionInfo} allSites={allSites}/> },
        { path: 'sitestatus/:urlSTSId',element: <SiteStatusPage msSessionInfo={msSessionInfo} allSites={allSites}/>},
        { path: 'reporting', element: <ReportingPage msSessionInfo={msSessionInfo} allSites={allSites}/> }
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'redirect',
      element: <Navigate to="/"/>,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]); 
  return authenticatedRoutes;
}

export default function Router() {
  const { instance } = useMsal();
  const [msSession, setMsSession] = useState();
  const [sitesList, setSitesList] = useState();

  useEffect(() => {
    let calledOnceOnPageLoad = false;
    if (!calledOnceOnPageLoad) {
      getAccessTokenAndUserInfoFromMS();      
      calledOnceOnPageLoad = true;
    }  
  }, []);
  
  const getAccessTokenAndUserInfoFromMS = () => {
    // Get token for api-specific scope: 
    instance.acquireTokenSilent(tokenRequest)
      .then(response => {
        const msSession = {
          'userFullName': response.idTokenClaims.name, 
          'accessToken' : response.accessToken
        };
        setMsSession(msSession);
        getSTSList(msSession);
      }).catch(err => {
        console.log(err);
      });
  }

  const getSTSList = (msSession) => {
    // GET STS Basic Info: 
    const apiConfig = {
      baseURL: sysoApiConfig.baseSYSOUrl,
      headers: { Authorization: `Bearer ${msSession.accessToken}` }
    };
    const axiosClientVar = axios.create(apiConfig);
    axiosClientVar.get('/sts/').then((response) => {
      setSitesList(sortSites(response.data));
    });    
  }

  return (
    <>
      <UnauthenticatedTemplate>
        <UnauthenticatedRouter/>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <AuthenticatedRouter msSessionInfo = {msSession} allSites = {sitesList}/>
      </AuthenticatedTemplate>
    </>
  );
}
