import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {Grid} from '@mui/material';
import { transformChartData } from '../../utils/apiCommonMethods';
import {AppChartCard} from '../@dashboard/app';

SiteStatusCharts.propTypes = {
  chartData: PropTypes.object
};

export default function SiteStatusCharts({chartData}) {
  const defaultChartSizes = {
    'chartOne': { 'sm': 12, 'lg': 6, showRow : true},
    'chartTwo': { 'sm': 12, 'lg': 6, showRow : true},
    'chartThree': { 'sm': 12, 'lg': 6, showRow : true},
    'chartFour': { 'sm': 12, 'lg': 6, showRow : true},
    'chartFive': { 'sm': 12, 'lg': 12, showRow : true}
  };

  
  // Default to size 6 (50%), expand to size 12 (100%)
  // On small screen, always 100% width
  const [chartSizes, setChartSizes] = useState(defaultChartSizes)

  const defaultBlank = [{
    name: '_',
    type: 'line',
    fill: 'solid',
    data: [0]
  }];

  // X-Axis Labels, so time stamps from dataset:
  const [dataLabelPower, setDataLabelPower] = useState([]);
  const [dataLabelSoc, setDataLabelSoc] = useState([]);
  const [dataLabelPoi, setDataLabelPoi] = useState([]);
  const [dataLabelOptim, setDataLabelOptim] = useState([]);
  const [dataLabelPrices, setDataLabelPrices] = useState([]);

  // Y-Axis Dataset, so actual measure values: 
  const [dataConfigPower, setDataConfigPower] = useState(defaultBlank);
  const [dataConfigSoc, setDataConfigSoc] = useState(defaultBlank);
  const [dataConfigPoi, setDataConfigPoi] = useState(defaultBlank);
  const [dataConfigOptim, setDataConfigOptim] = useState(defaultBlank);
  const [dataConfigPrices, setDataConfigPrices] = useState(defaultBlank);

  useEffect(() => {
    if (chartData != null) {
      const cleanData = transformChartData(chartData);
      console.log(cleanData);

      // ______________POWER ______________
      if (cleanData.power.labels.length > 0){
        chartSizes.chartOne.showRow = true;
        setChartSizes(chartSizes);
        setDataLabelPower(cleanData.power.labels);
        const chartDataVarPower = [{
          name: 'Power',
          type: 'line',
          fill: 'solid',
          data: cleanData.power.dataSet
        }];
        setDataConfigPower(chartDataVarPower);
      } else {
        setDataLabelPower([]);
        setDataConfigPower(defaultBlank);
      }
      
      // ______________SOC ________________
      if (cleanData.soc.labels.length > 0){
        chartSizes.chartTwo.showRow = true;
        setChartSizes(chartSizes);
        setDataLabelSoc(cleanData.soc.labels);
        const chartDataVarSoc = [{
          name: 'SOC %',
          type: 'line',
          fill: 'solid',
          data: cleanData.soc.dataSet
        }];
        setDataConfigSoc(chartDataVarSoc);
      } else {
        setDataLabelSoc([]);
        setDataConfigSoc(defaultBlank);
      }

      // ______________Grid Output ________________
      if (cleanData.pvToGrid && cleanData.pvToGrid.labels.length > 0) {
        chartSizes.chartFive.showRow = true;
        setChartSizes(chartSizes);
        setDataLabelPoi(cleanData.pvToGrid.labels);
        const chartDataVarPoi = [{
          name: 'Grid Output',
          type: 'area',
          stacked: true,
          fill: 'solid',
          data: cleanData.pvToGrid.dataSet
        },
        { 
          name: 'Battery Charge',
          type: 'area',
          stacked: true,
          fill: 'solid',
          data: cleanData.bessImport.dataSet
        },
        { 
          name: 'Battery Discharge',
          type: 'area',
          stacked: true,
          fill: 'solid',
          data: cleanData.bessExport.dataSet
        }];
        setDataConfigPoi(chartDataVarPoi);
      } else {
        chartSizes.chartFive.showRow = false;
        setDataLabelPoi([]);
        setDataConfigPoi(defaultBlank);
      }

      // ___________Optimization _____________
      if (cleanData.optimSOC && cleanData.optimSOC.labels.length > 0){
        setDataLabelOptim(cleanData.optimSOC.labels);
        const chartDataVarOptim = [
          {
            name: 'VDER Power',
            type: 'column',
            fill: 'solid',
            data: cleanData.vderOptimPower.dataSet
          },
          {
            name: 'DLM Power',
            type: 'column',
            fill: 'solid',
            data: cleanData.dlmOptimPower.dataSet
          },
          {
            name: 'SOC',
            type: 'line',
            fill: 'solid',
            data: cleanData.optimSOC.dataSet
          }
        ];
        setDataConfigOptim(chartDataVarOptim);
      } else {
        setDataLabelOptim([]);
        setDataConfigOptim(defaultBlank);
      }

      // ___________Prices ________________
      if (cleanData.prices && cleanData.prices.labels.length > 0){
        setDataLabelPrices(cleanData.prices.labels);
        const chartDataVarPrices = [{
          name: 'Price',
          type: 'column',
          fill: 'solid',
          data: cleanData.prices.dataSet
        }];
        setDataConfigPrices(chartDataVarPrices);
      } else {
        setDataLabelPrices([]);
        setDataConfigPrices(defaultBlank);
      }
    }
  }, [chartData]);

  const expandContractChartOne = () => {
    if (chartSizes.chartTwo.showRow === false){
      setChartSizes(defaultChartSizes);
    } else {
      setChartSizes({
        'chartOne': { 'sm': 12, 'lg': 12, showRow : true},
        'chartTwo': { 'sm': 12, 'lg': 6, showRow : false},
        'chartThree': { 'sm': 12, 'lg': 6, showRow : false},
        'chartFour': { 'sm': 12, 'lg': 6, showRow : false}
      });
    }
    window.dispatchEvent(new Event('resize'));
  }

  const expandContractChartTwo = () => {
    if (chartSizes.chartOne.showRow === false){
      setChartSizes(defaultChartSizes);
    } else {
      setChartSizes({
        'chartOne': { 'sm': 12, 'lg': 6, showRow : false},
        'chartTwo': { 'sm': 12, 'lg': 12, showRow : true},
        'chartThree': { 'sm': 12, 'lg': 6, showRow : false},
        'chartFour': { 'sm': 12, 'lg': 6, showRow : false}
      });
    }
    window.dispatchEvent(new Event('resize'));
  }

  const expandContractChartThree = () => {
    if (chartSizes.chartOne.showRow === false){
      setChartSizes(defaultChartSizes);
    } else {
      setChartSizes({
        'chartOne': { 'sm': 12, 'lg': 6, showRow : false},
        'chartTwo': { 'sm': 12, 'lg': 6, showRow : false},
        'chartThree': { 'sm': 12, 'lg': 12, showRow : true},
        'chartFour': { 'sm': 12, 'lg': 6, showRow : false}
      });
    }
    window.dispatchEvent(new Event('resize'));
  }

  const expandContractChartFour = () => {
    if (chartSizes.chartOne.showRow === false){
      setChartSizes(defaultChartSizes);
    } else {
      setChartSizes({
        'chartOne': { 'sm': 12, 'lg': 6, showRow : false},
        'chartTwo': { 'sm': 12, 'lg': 6, showRow : false},
        'chartThree': { 'sm': 12, 'lg': 6, showRow : false},
        'chartFour': { 'sm': 12, 'lg': 12, showRow : true}
      });
    }
    window.dispatchEvent(new Event('resize'));
  }
  
  return (
    <>
      <Grid container spacing={3}>
        {chartSizes.chartOne.showRow && dataLabelPower.length > 0 ? (
          <Grid item xs={chartSizes.chartOne.sm} md={chartSizes.chartOne.lg} lg={chartSizes.chartOne.lg}>
            <AppChartCard
              title="Battery Power"
              subheader="kW"
              xAxisLabel=""
              expandContractChart={expandContractChartOne}
              chartLabels={dataLabelPower}
              chartData={dataConfigPower}
            />
          </Grid>
        ) : (null)}
        {chartSizes.chartTwo.showRow && dataLabelSoc.length > 0 ? (
          <Grid item xs={chartSizes.chartTwo.sm} md={chartSizes.chartTwo.lg} lg={chartSizes.chartTwo.lg}>
            <AppChartCard
              title="State of Charge"
              subheader="SOC %"
              xAxisLabel=""
              expandContractChart={expandContractChartTwo}
              chartLabels={dataLabelSoc}
              chartData={dataConfigSoc}
            />
          </Grid>
        ) : (null)}
        {chartSizes.chartThree.showRow && dataLabelOptim.length > 0 ? (
          <Grid item xs={chartSizes.chartThree.sm} md={chartSizes.chartThree.lg} lg={chartSizes.chartThree.lg}>
            <AppChartCard
              title="Optimization Strategy"
              subheader="Battery Power (MW) and SOC (%)"
              xAxisLabel="Hour Beginning"
              expandContractChart={expandContractChartThree}
              doubleYAxis
              chartLabels={dataLabelOptim}
              chartData={dataConfigOptim}
            />
          </Grid>
        ) : (null)}
        {chartSizes.chartFour.showRow && dataLabelPrices.length > 0 ? (
          <Grid item xs={chartSizes.chartFour.sm} md={chartSizes.chartFour.lg} lg={chartSizes.chartFour.lg}>          
            <AppChartCard
              title="Day Ahead LBMP"
              subheader="Prices ($/MWH)"
              xAxisLabel="Hour Beginning"
              expandContractChart={expandContractChartFour}
              chartLabels={dataLabelPrices}
              chartData={dataConfigPrices}
            />
          </Grid>
        ) : (null)}
        {chartSizes.chartFive.showRow && dataLabelSoc.length > 0 ? (
          <Grid item xs={chartSizes.chartFive.sm} md={chartSizes.chartFive.lg} lg={chartSizes.chartFive.lg}>
            <AppChartCard
              title="Grid Output"
              subheader="kW"
              xAxisLabel=""
              expandContractChart={expandContractChartTwo}
              chartLabels={dataLabelPoi}
              chartData={dataConfigPoi}
            />
          </Grid>
        ) : (null)}
      </Grid>
    </>
  );
}