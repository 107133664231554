import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab, Typography, Box} from '@mui/material';
import {SiteStatus} from '../sections/siteStatus';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function SiteStatusPage({msSessionInfo, allSites}) {
  const { urlSTSId } = useParams();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let calledOnceOnPageLoad = false;
    if (!calledOnceOnPageLoad && allSites) {
      calledOnceOnPageLoad = true;
      if (urlSTSId !== null){
        console.log(`opening ${urlSTSId}`);
        allSites.forEach(openTabFromURLParam);
      }
    }
    function openTabFromURLParam(tab, index) {
      if (tab.stsId === urlSTSId){
        setValue(index);
      }
    }
  }, [allSites]);

  return (
    <>
      <Helmet>
        <title>SYSO Site Status</title>
      </Helmet>

      <Box sx={{ pl:5, pr:5}}>
      <Typography variant="h4" sx={{pb:1}}>Site Status</Typography>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={value} 
            onChange={handleChange}
            aria-label="SiteStatus"   
            variant="scrollable" 
            scrollButtons="auto">
            {allSites?.map((site) => (
              <Tab key={site.stsId} label={site.displayName}/>
            ))}
          </Tabs>
        </Box>
        {allSites?.map((site, index) => (
          <TabPanel key={site.stsId} value={value} index={index}>
            <SiteStatus msSessionInfo={msSessionInfo} site={site}/>
          </TabPanel>
        ))}
      </Box>
    </>
  );
}
