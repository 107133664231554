import { Helmet } from 'react-helmet-async';
import { useMsal } from '@azure/msal-react';
import { styled } from '@mui/material/styles';
import { Container, Typography, Box, Button } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { loginRequest } from '../authConfig';
import Logo from '../components/logo';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 400,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect({
      ...loginRequest
    }).catch((error) => console.log(error));
  }

  return (
    <>
      <Helmet>
        <title>SYSO Login</title>
      </Helmet>

      <StyledRoot sx={{bgcolor: (theme) => theme.palette.background.paper}}>
        <Container maxWidth="sm">
          <StyledContent>
            <Box sx={{pl:8, pr:8, pb:4, display: 'inline-flex'}}>
              <Logo/>
            </Box>
            <Button variant="contained" onClick={handleLogin}>
              <Typography variant="h6">
                Sign in with Microsoft
              </Typography>
            </Button>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
