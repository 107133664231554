const navConfig = [
  {
    title: 'Home',
    path: '/dashboard/home',
    icon: 'Home'
  },
  {
    title: 'Site Status',
    path: '/dashboard/sitestatus',
    icon: 'BarChart'
  },
  {
    title: 'Reporting',
    path: '/dashboard/reporting',
    icon: 'Description'
  }
];

export default navConfig;
