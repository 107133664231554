import { env } from './envVariables';

export const sysoApiConfig = {
    baseSYSOUrl: env.baseSYSOAPIUrl
}

export const ihiModeMap = new Map([
    [0, "Unknown"], [1, "Shutdown"], [2, "Waiting"], [3, "Startup"], [4, "Ready"], [5, "Standby"],
    [6, "Profile"], [7, "Peak Shaving"], [8, "Frequency Support"], [9, "Voltage Support"],
    [10, "Load Leveling"], [11, "Manual"], [12, "SOC Maintenance"], [13, "Ramp Maintenance"],
    [14, "ISO Dispatch"], [15, "Rejuvenation"], [16, "Maintenance"], [17, "Fault"],
    [18, "Grid Support"], [19, "DC Coupled Manual"], [20, "DC Coupled ISO Dispatch"],
    [21, "DC Coupled SOC Maintenance"], [22, "DC Coupled PV Support"]
]);

export const geModeMap = new Map([
    [0, "Fault"],
    [1, "Shutdown"],
    [2, "Default"],
    [3, "Remote P"],
    [4, "Firming"]
]);

export const eneonModeMap = new Map([
    [0, "Grid Following"], [1, "Grid Forming"], [2, "Direct Battery Control"], 
    [3, "SOC Target"], [4, "Clipping Management"], [5, "Power Firmning"],
    [6, "Clipping Management and Power Firming"], [7, "PV to Grid"]
]);

export const flexgenModeMap = new Map([
    [0, "PV Only"], [1, "Direct Battery Control"], [3, "PV Clipping"],
    [4, "Min System Output"], [7, "Shutdown"]
]); 

export const indieModeMap = new Map([
    [0, "Ready"], [1, "Standby"], [2, "Running"], [3, "Stopping"],
    [4, "Fault"], [5, "Waiting"], [99, "Unknown"], [999, "Unknown"]
]);

export const heilaModeMap = new Map([
    [1, "Hard Discharge"], [2, "Charge"], [3, "Soft Discharge"]
]);

export const siteToModeMap = new Map([
    ['17qeqym',geModeMap],
    ['17qequh',geModeMap],
    ['17qemb3',eneonModeMap],
    ['17qeeji',eneonModeMap],
    ['17qeb9b',eneonModeMap],
    ['17qeje8',flexgenModeMap],
    ['17qemjb',indieModeMap],
    ['17qek1u',indieModeMap],
    ['17qeirk',ihiModeMap],
    ['17qemwn',ihiModeMap],
    ['17qef86',ihiModeMap],
    ['17q5s8d',ihiModeMap],
    ['17qegkh',ihiModeMap],
    ['17qeqxk',ihiModeMap],
    ['17qeura',ihiModeMap],
    ['17qehxt',ihiModeMap],
    ['17qeets',ihiModeMap],
    ['17tjhu6',ihiModeMap],
    ['17qegmi',ihiModeMap],
    ['17qerg4',ihiModeMap],
    ['17qegqm',ihiModeMap],
    ['17tjg1e',ihiModeMap],
    ['17qefec',ihiModeMap],
    ['17qeea9',ihiModeMap],
    ['17qem6y',ihiModeMap],
    ['17qemd5',ihiModeMap],
    ['17qefsq',heilaModeMap]
]);

export const scalingMap = new Map([
    ['default',{'soc' : 'divide10', 'power' : null}],
]);

export const measureApiNameToConfigsMap = new Map([
    ['averageSoc',{'type' : 'decimal', 'label' : 'Average SOC', 'decimalPlaces' : 2}],
    ['batteryExport',{'type' : 'decimal', 'label' : 'Battery Export', 'decimalPlaces' : 0}],
    ['batteryImport',{'type' : 'decimal', 'label' : 'Battery Import', 'decimalPlaces' : 0}],
    ['fullCycleEquivalents',{'type' : 'decimal', 'label' : 'Full Cycle Equivalents', 'decimalPlaces' : 2}],
    ['gridOutput',{'type' : 'decimal', 'label' : 'Grid Output', 'decimalPlaces' : 0}],

    ['capacitySupplyObligation',{'type' : 'decimal', 'label' : 'Capacity Supply Obligation', 'decimalPlaces' : 2}],
    ['weightedClearingPrice',{'type' : 'currency', 'label' : 'Weighted Clearing Price', 'decimalPlaces' : 2}],
    ['monthlyRevenue',{'type' : 'currency', 'label' : 'Monthly Revenue', 'decimalPlaces' : 2}],
    
    ['assetId',{'type' : 'decimal', 'label' : 'Asset', 'decimalPlaces' : 2}],
    ['hoursOnRegulation',{'type' : 'decimal', 'label' : 'Hours on Regulation', 'decimalPlaces' : 0}],
    ['avgRips',{'type' : 'decimal', 'label' : 'Average RIPS Score', 'decimalPlaces' : 4}],
    ['totalPayments',{'type' : 'currency', 'label' : 'Total Payments', 'decimalPlaces' : 2}],
    ['avgCapacityCredit',{'type' : 'currency', 'label' : 'Average Capacity Credit', 'decimalPlaces' : 2}],
    ['avgServiceCredit',{'type' : 'currency', 'label' : 'Average Service Credit', 'decimalPlaces' : 2}],

    ['peakHourCpecs',{'type' : 'decimal', 'label' : 'Peak Hour CPECs', 'decimalPlaces' : 2}],
    ['peakHourOutput',{'type' : 'decimal', 'label' : 'Peak Hour Output', 'decimalPlaces' : 0}],
    ['seasonalHourCpecs',{'type' : 'decimal', 'label' : 'Seasonal Hour Output', 'decimalPlaces' : 0}],
    ['seasonalHourOutput',{'type' : 'decimal', 'label' : 'Seasonal Hour Output', 'decimalPlaces' : 0}],
    ['peakHour',{'type' : 'datetime', 'label' : 'Peak Hour'}],
    ['totalCpecs',{'type' : 'decimal', 'label' : 'Total CPECs', 'decimalPlaces' : 0}],

    ['marketRevenue',{'type' : 'currency', 'label' : 'Market Revenue', 'decimalPlaces' : 2}],
    ['sysoFees',{'type' : 'currency', 'label' : 'SYSO Fees', 'decimalPlaces' : 2}],
    ['totalRemittance',{'type' : 'currency', 'label' : 'Total Remittance', 'decimalPlaces' : 2}],
    ['statementDate',{'type' : 'date', 'label' : 'Statement Date'}],
    ['statementNumber',{'type' : 'string', 'label' : 'Statement Number', 'decimalPlaces' : 2}],

    ['gridOutputDrv',{'type' : 'decimal', 'label' : 'Grid Output DRV', 'decimalPlaces' : 2}],
    ['gridOutputIcapTarget',{'type' : 'decimal', 'label' : 'Grid Output ICAP Target', 'decimalPlaces' : 2}],
    ['lsrvDispatchHours',{'type' : 'decimal', 'label' : 'LSRV Dispatch Hours', 'decimalPlaces' : 0}]
]);

export const tableApiNameToLabel = new Map([
    ['batteryHealth','Battery Health'],
    ['isoNeCapacity','ISO-NE Capacity'],
    ['isoNeRegulation','ISO-NE Regulation'],
    ['maCleanPeak','MA Clean Peak'],
    ['remittanceSummary','Remittance Summary'],
    ['vder','New York VDER']
]);

export const reportingPackagesEnabledForDetail = new Map([
    ['batteryHealth',false],
    ['isoNeCapacity',false],
    ['isoNeRegulation',false],
    ['maCleanPeak',false],
    ['remittanceSummary',false],
    ['vder',true]
]);

/* 
    useDemoSiteNames is a switch to use fake anonymized names 
    Future improvement: have this be driven off user 
*/
export const useDemoSiteNames = false;

export const demoSiteNames = [
    'Boylston', 'Commonwealth', 'Newbury', 
    'Tremont', 'Beacon', 'Huntington', 'Marlborough', 
    'Washington', 'Clarendon', 'Berkeley', 'Dartmouth', 
    'Columbus', 'Exeter', 'Hereford', 'Gloucester', 
    'Fairfield', 'Arlington', 'Stanhope', 'Yarmouth', 
    'Copley', 'Somerset', 'Brimmer', 'Fenway', 'Bowdoin', 
    'Newcomb', 'Ipswich', 'Shawmut', 'Temple', 'Congress', 'Court'
];