import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Router from './routes';
import ThemeProvider from './theme';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MsalProvider, useMsal } from '@azure/msal-react';

// ----------------------------------------------------------------------

const MainContent = () => {
  const { instance } = useMsal();
 // const activeAccount = instance.getActiveAccount();

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ScrollToTop />
            <StyledChart />
            <Router />
          </LocalizationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

const App = ({ instance }) => {
  return (
      <MsalProvider instance={instance}>
        <MainContent/>
      </MsalProvider>
  );
};
export default App;