import { Helmet } from 'react-helmet-async';
import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container, Grid, Tabs, Tab, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ReportingSummaryComponent, ReportingDetailComponent } from '../sections/reporting';
import { getGeneralAPIRequest } from '../utils/apiCommonMethods';
import { readPackageData, getUniqueSites } from '../utils/apiCommonReportingMethods';

// ---------------------------------------------------------------------

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ReportingPage({msSessionInfo, allSites}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [sitesWithPackages, setSitesWithPackages] = useState();

  const handleSiteTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (allSites != null) {
      const axiosSession = getGeneralAPIRequest(msSessionInfo);
      const getAvailablePackages = async (axiosSession) => {
        const uniqueSites = getUniqueSites(allSites);
        const paramsVar = new URLSearchParams();
        for (let j = 0; j < uniqueSites.length; j += 1){
          paramsVar.append("siteIds",uniqueSites[j].siteId);
        }
        const apiRequest = {
          params: paramsVar
        };
        const apiResponse = await axiosSession.get('/reporting/packages', apiRequest);
        const newSitesList = readPackageData(uniqueSites, apiResponse.data);
        setSitesWithPackages(newSitesList);
      }
      getAvailablePackages(axiosSession);
    }
  }, [msSessionInfo, allSites]);

  return (
    <>
      <Helmet>
        <title>SYSO Reporting</title>
      </Helmet>

      <Box sx={{ pl:5, pr:5}}>
      <Typography variant="h4" sx={{pb:4}}>Site Reporting</Typography>
        <Container maxWidth="xl">
          <Grid container spacing={3} direction="row" alignItems="stretch" justifyContent="center">
            <Grid item xs={12} md={12} lg={12}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={selectedTab}
                  onChange={handleSiteTabChange}
                  aria-label="SiteStatus"
                  variant="scrollable"
                  scrollButtons="auto">
                  {sitesWithPackages?.map((site) => (
                    <Tab key={site.siteId} label={site.displayName} />
                  ))}
                </Tabs>
              </Box>
              {sitesWithPackages?.map((site, index) => (
                <TabPanel key={site.siteId} value={selectedTab} index={index}>
                  <div>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header">
                        Monthly Summary Reporting
                      </AccordionSummary>
                      <AccordionDetails>
                        <ReportingSummaryComponent msSessionInfo={msSessionInfo} singleSite={site}/>
                      </AccordionDetails>
                    </Accordion>
                    {site.detailReportingEnabled ?
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header">
                          Hourly Detail Reporting
                        </AccordionSummary>
                        <AccordionDetails>
                          <ReportingDetailComponent msSessionInfo={msSessionInfo} singleSite={site}/>
                        </AccordionDetails>
                      </Accordion>
                    : (null)}
                  </div>
                </TabPanel>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>          
    </>
  );
}
